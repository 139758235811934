<template>
  <div id="app">
    <projectNav/>
    <router-view/>
     <projectFoot/>
  </div>
</template>
<script>
import projectNav from './components/ProjectNav'
import projectFoot from './components/ProjectFoot'
import { web3Accounts } from "@polkadot/extension-dapp";
import { mapActions } from "vuex";
export default {
  created(){
    this.init();
    web3Accounts();
  },
  methods: {
    ...mapActions(["setChain", "setUser"]),
    init(chain = this.config.chain) {
      if (!this.chainList[chain]) {
        chain = this.config.chain;
        this.$router.push(this.root);
      }
      // connect wss
      if (!this.chainList[chain].ws) {
        this.setUser(chain);
      }

      // toggle chain
      if (this.currentChain != chain) {
        this.setChain(chain);
      }
    }
  },
  components:{
    projectNav,
    projectFoot
  },
}
</script>
