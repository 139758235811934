import { ApiPromise, WsProvider } from "@polkadot/api";
import {
  isWeb3Injected,
  web3Accounts,
  web3Enable,
  web3FromAddress,
  web3FromSource,
} from "@polkadot/extension-dapp";
import util from './util'
import chainList from './config'
import { u8aConcat, stringToU8a, u8aEq, BN_ONE, BN_ZERO, u8aToHex ,BN,extractTime} from '@polkadot/util';
import { blake2AsU8a, encodeAddress } from '@polkadot/util-crypto';

web3Enable( "PolkaProject" );

const CROWD_PREFIX = stringToU8a( 'modlpy/cfund' );
const EMPTY_U8A = new Uint8Array( 32 );
const RANGES = [
  [0, 0], [0, 1], [0, 2], [0, 3],
  [1, 1], [1, 2], [1, 3],
  [2, 2], [2, 3],
  [3, 3]
];

function createChildKey( trieIndex ) {
  return u8aToHex(
    u8aConcat(
      ':child_storage:default:',
      blake2AsU8a(
        u8aConcat( 'crowdloan', trieIndex.toU8a() )
      )
    )
  );
}

function createAddress( paraId ) {
  return u8aConcat( CROWD_PREFIX, paraId.toU8a(), EMPTY_U8A ).subarray( 0, 32 );
}

function isCrowdloadAccount( paraId, accountId ) {
  return accountId.eq( createAddress( paraId ) );
}

class User {
  constructor( chain ) {
    let provider = new WsProvider( chainList[chain].websockets[0] );
    this.api = ApiPromise.create( { provider } );
    this.ss58Format = chainList[chain].ss58Formats;
    this.decimals = chainList[chain].decimals
  }

  // 获取钱包账号
  async getAccounts() {
    if ( isWeb3Injected ) {
      return await web3Accounts( { ss58Format: this.ss58Format } ).then(
        ( accounts ) => {
          accounts.forEach( ( account ) => {
            account.isActive = false;
          } );
          return accounts;
        }
      );
    }
  }

  // 获取账户余额
  async getBalance( account ) {
    const api = await this.api;
    let balanceList = await api.derive.balances.all( account.address )
    return balanceList?.availableBalance.toString()
  }

  async getAllBalance( account ) {
    const api = await this.api;
    return await api.derive.balances.all( account.address )
  }

  async getCurrentBlock() {
    const api = await this.api;
    let block = await api.derive.chain.bestNumber()
    return block
  }
  async getCurrentPeriod() {
    const api = await this.api;
    let bestNumber = (await this.getCurrentBlock()).toString()
    let period = api.consts.slots.leasePeriod
    let cyrrentPeriod = Math.floor( bestNumber / period );
    return [cyrrentPeriod, bestNumber]
  }

  // 获取竞拍 第几期, 周期, 结束时间
  async getAuctionInfo() {
    const api = await this.api;
    let numAuctions = ( await api.query.auctions.auctionCounter() ).toString();
    let optInfo = await api.query.auctions.auctionInfo();
    const [leasePeriod, endBlock] = optInfo.unwrapOr( [null, null] );
    return {
      numAuctions,
      leasePeriod,
      endBlock
    }
    // return {
    // leasePeriod: info.toJSON()[0],
    // endPeriod: info.toJSON()[0]+3,
    // endBlock: info,
    // }
  }

  /** 获取竞拍数据系列方法 */
  isNewWinners( a, b ) {
    return JSON.stringify( { w: a } ) !== JSON.stringify( { w: b } );
  }
  isNewOrdering( a, b ) {
    return a.length !== b.length || a.some( ( { firstSlot, lastSlot, paraId }, index ) =>
      !paraId.eq( b[index].paraId ) ||
      !firstSlot.eq( b[index].firstSlot ) ||
      !lastSlot.eq( b[index].lastSlot )
    );
  }
  extractWinners( ranges, auctionInfo, optData ) {
    return optData.isNone
      ? []
      : optData.unwrap().reduce( ( winners, optEntry, index ) => {
        if ( optEntry.isSome ) {
          const [accountId, paraId, value] = optEntry.unwrap();
          const period = auctionInfo.leasePeriod || BN_ZERO;
          const [first, last] = ranges[index];

          winners.push( {
            accountId: accountId.toString(),
            firstSlot: period.addn( first ),
            isCrowdloan: u8aEq( CROWD_PREFIX, accountId.subarray( 0, CROWD_PREFIX.length ) ),
            lastSlot: period.addn( last ),
            paraId,
            value
          } );
        }

        return winners;
      }, [] );
  }
  createWinning( { endBlock }, blockOffset, winners ) {
    return {
      blockNumber: endBlock && blockOffset
        ? blockOffset.add( endBlock )
        : blockOffset || BN_ZERO,
      blockOffset: blockOffset || BN_ZERO,
      total: winners.reduce( ( total, { value } ) => total.iadd( value ), BN_ZERO ),
      winners
    };
  }
  extractData( ranges, auctionInfo, values ) {
    return values
      .sort( ( [{ args: [a] }], [{ args: [b] }] ) => a.cmp( b ) )
      .reduce( ( all, [{ args: [blockOffset] }, optData] ) => {
        const winners = this.extractWinners( ranges, auctionInfo, optData );

        winners.length && (
          all.length === 0 ||
          this.isNewWinners( winners, all[all.length - 1].winners )
        ) && all.push( this.createWinning( auctionInfo, blockOffset, winners ) );

        return all;
      }, [] )
      .reverse();
  }
  mergeCurrent( ranges, auctionInfo, prev, optCurrent, blockOffset ) {
    const current = this.createWinning( auctionInfo, blockOffset, this.extractWinners( ranges, auctionInfo, optCurrent ) );

    if ( current.winners.length ) {
      if ( !prev || !prev.length ) {
        return [current];
      }

      if ( this.isNewWinners( current.winners, prev[0].winners ) ) {
        if ( this.isNewOrdering( current.winners, prev[0].winners ) ) {
          return [current, ...prev];
        }

        prev[0] = current;

        return [...prev];
      }
    }

    return prev;
  }
  mergeFirst( ranges, auctionInfo, prev, optFirstData ) {
    if ( prev && prev.length <= 1 ) {
      const updated = prev || [];
      const firstEntry = this.createWinning( auctionInfo, null, this.extractWinners( ranges, auctionInfo, optFirstData ) );

      if ( !firstEntry.winners.length ) {
        return updated;
      } else if ( !updated.length ) {
        return [firstEntry];
      }

      updated[updated.length - 1] = firstEntry;

      return updated.slice();
    }

    return prev;
  }
  // 获取获胜列表
  async getWinningData() {
    const api = await this.api
    const ranges = await this.getRanges();
    const bestNumber = await api.derive.chain.bestNumber();
    const initialEntries = await api.query.auctions?.winning.entries();
    const optFirstData = await api.query.auctions?.winning( 0 );

    const auctionInfo = await this.getAuctionInfo();
    let prev = null;
    if ( auctionInfo && initialEntries ) {
      prev = this.extractData( ranges, auctionInfo, initialEntries )
    }
    if ( auctionInfo && optFirstData ) {
      prev = this.mergeFirst( ranges, auctionInfo, prev, optFirstData )
    }


    if ( auctionInfo?.endBlock && bestNumber && bestNumber.gt( auctionInfo.endBlock ) ) {
      const blockOffset = bestNumber.sub( auctionInfo.endBlock ).iadd( BN_ONE );

      let optCurrent = null;
      try {
        optCurrent = await api.query.auctions?.winning( blockOffset );
        return {
          auctionInfo,
          winningData: this.mergeCurrent( ranges, auctionInfo, prev, optCurrent, blockOffset ),
        }
      } catch (error) {
        console.log(error);
      }
    }

    return { auctionInfo, winningData: prev };
  }
  async getRanges() {
    const api = await this.api;
    let ranges = []
    if ( !!( api.consts.auctions?.leasePeriodsPerSlot ) ) {
      for ( let i = 0; api.consts.auctions.leasePeriodsPerSlot.gtn( i ); i++ ) {
        for ( let j = i; api.consts.auctions.leasePeriodsPerSlot.gtn( j ); j++ ) {
          ranges.push( [i, j] );
        }
      }
    } else {
      ranges = RANGES;
    }

    return ranges
  }
  async getRangeMax() {
    let ranges = await this.getRanges()
    return new BN( ranges[ranges.length - 1][1] )
  }
  interleave( winners, newRaise, asIs, auctionInfo, campaigns, rangeMax ) {
    if ( asIs || !newRaise || !auctionInfo?.leasePeriod ) {
      return winners;
    }

    const leasePeriod = auctionInfo.leasePeriod;
    const leasePeriodEnd = leasePeriod.add( rangeMax );

    const sorted = ( campaigns.funds || [] )
      .filter( ( { firstSlot, lastSlot, isWinner, paraId } ) =>
        !isWinner && newRaise.some( ( n ) => n.eq( paraId ) ) &&
        firstSlot.gte( leasePeriod ) &&
        lastSlot.lte( leasePeriodEnd )
      )
      .sort( ( a, b ) => b.value.cmp( a.value ) );

    let result = winners
      .concat( ...sorted.filter( ( { firstSlot, lastSlot, paraId, value } ) =>
        !winners.some( ( w ) =>
          w.firstSlot.eq( firstSlot ) &&
          w.lastSlot.eq( lastSlot )
        ) &&
        !sorted.some( ( e ) =>
          !paraId.eq( e.paraId ) &&
          firstSlot.eq( e.firstSlot ) &&
          lastSlot.eq( e.lastSlot ) &&
          value.lt( e.value )
        )
      ) )
      .map( ( w ) =>
        sorted.find( ( { firstSlot, lastSlot, value } ) =>
          w.firstSlot.eq( firstSlot ) &&
          w.lastSlot.eq( lastSlot ) &&
          w.value.lt( value )
        ) || w
      )
      .sort( ( a, b ) =>
        a.firstSlot.eq( b.firstSlot )
          ? a.lastSlot.cmp( b.lastSlot )
          : a.firstSlot.cmp( b.firstSlot )
      );
    return result;
  }
  // 获取竞拍列表
  async getAuctionList() {
    const api = await this.api;
    const newRaise = await api.query.crowdloan.newRaise();
    const campaigns = await this.useFunds()
    const rangeMax = await this.getRangeMax();
    const { auctionInfo, winningData } = await this.getWinningData();

    let list = auctionInfo && newRaise && winningData && (
      winningData.length
        ? winningData.map( ( { blockNumber, winners }, round ) => (
          this.interleave( winners, newRaise, round !== 0 || winningData.length !== 1, auctionInfo, campaigns, rangeMax ).map( ( value, index ) => ( {
            // auctionInfo,
            // blockNumber,
            // isFirst: index === 0,
            // isLatest: round === 0,
            ...value
          } )
          )
        ) ) : newRaise && ( newRaise.length !== 0 ) && (
          this.interleave( [], newRaise, false, auctionInfo, campaigns, rangeMax ).map( ( value, index ) => ( {
            // auctionInfo,
            // isFirst: index === 0,
            // isLatest:true,
            ...value,
          } )
          ) )
    )
    return list&&list.length&&list[0];
  }
  async useFunds() {
    const api = await this.api;
    let prev = null;
    const bestNumber = await api.derive.chain.bestNumber();
    let paraIds = ( await api.query.crowdloan.funds.keys() ).map( ( { args } ) => args[0] );
    let leased = await api.query.slots.leases.multi( paraIds );
    let leases = paraIds.filter( ( paraId, i ) => {
      return leased[i]
        .map( ( o ) => o.unwrapOr( null ) )
        .filter( ( v ) => !!v )
        .filter( ( [accountId] ) => {
          return isCrowdloadAccount( paraId, accountId )
        } )
        .length !== 0
    } )

    let optFunds = await api.query.crowdloan?.funds.multi( paraIds )
    let campaigns = paraIds.map( ( paraId, i ) => [paraId, optFunds[i].unwrapOr( null )] )
      .filter( ( v ) => !!v[1] )
      .map( ( [paraId, info] ) => ( {
        accountId: encodeAddress( createAddress( paraId ) ),
        childKey: createChildKey( info.trieIndex ),
        firstSlot: info.firstPeriod,
        info,
        isCrowdloan: true,
        key: paraId.toString(),
        lastSlot: info.lastPeriod,
        paraId,
        value: info.raised
      } ) )
      .sort( ( a, b ) =>
        a.info.end.cmp( b.info.end ) ||
        a.info.firstPeriod.cmp( b.info.firstPeriod ) ||
        a.info.lastPeriod.cmp( b.info.lastPeriod ) ||
        a.paraId.cmp( b.paraId )
      )
    const result = bestNumber && campaigns && leases && this.createResult( bestNumber, api.consts.crowdloan.minContribution, campaigns, leases, prev )
    return result;
  }
  isFundUpdated( bestNumber, minContribution, { info: { cap, end, raised }, paraId }, leased, allPrev ) {
    const prev = allPrev.funds?.find( ( p ) => p.paraId.eq( paraId ) );

    return !prev ||
      ( !prev.isEnded && bestNumber.gt( end ) ) ||
      ( !prev.isCapped && cap.sub( raised ).lt( minContribution ) ) ||
      ( !prev.isWinner && leased.some( ( l ) => l.eq( paraId ) ) );
  }
  updateFund( bestNumber, minContribution, data, leased ) {
    data.isCapped = data.info.cap.sub( data.info.raised ).lt( minContribution );
    data.isEnded = bestNumber.gt( data.info.end );
    data.isWinner = leased.some( ( l ) => l.eq( data.paraId ) );
    return data;
  }
  createResult( bestNumber, minContribution, funds, leased, prev ) {
    const [activeRaised, activeCap, totalRaised, totalCap] = funds.reduce( ( [ar, ac, tr, tc], { info: { cap, end, raised } } ) => [
      bestNumber.gt( end ) ? ar : ar.iadd( raised ),
      bestNumber.gt( end ) ? ac : ac.iadd( cap ),
      tr.iadd( raised ),
      tc.iadd( cap )
    ], [new BN( 0 ), new BN( 0 ), new BN( 0 ), new BN( 0 )] );
    const hasNewActiveCap = !prev || !prev.activeCap.eq( activeCap );
    const hasNewActiveRaised = !prev || !prev.activeRaised.eq( activeRaised );
    const hasNewTotalCap = !prev || !prev.totalCap.eq( totalCap );
    const hasNewTotalRaised = !prev || !prev.totalRaised.eq( totalRaised );
    const hasChanged =
      !prev || !prev.funds || prev.funds.length !== funds.length ||
      hasNewActiveCap || hasNewActiveRaised || hasNewTotalCap || hasNewTotalRaised ||
      funds.some( ( c ) => this.isFundUpdated( bestNumber, minContribution, c, leased, prev ) );

    if ( !hasChanged ) {
      return prev;
    }

    return {
      activeCap: hasNewActiveCap
        ? activeCap
        : prev.activeCap,
      activeRaised: hasNewActiveRaised
        ? activeRaised
        : prev.activeRaised,
      funds: funds
        .map( ( c ) => this.updateFund( bestNumber, minContribution, c, leased ) )
        .sort( this.sortCampaigns ),
      totalCap: hasNewTotalCap
        ? totalCap
        : prev.totalCap,
      totalRaised: hasNewTotalRaised
        ? totalRaised
        : prev.totalRaised
    };

  }
  sortCampaigns( a, b ) {
    return a.isWinner !== b.isWinner
      ? a.isWinner
        ? -1
        : 1
      : a.isCapped !== b.isCapped
        ? a.isCapped
          ? -1
          : 1
        : a.isEnded !== b.isEnded
          ? a.isEnded
            ? 1
            : -1
          : 0;
  }
  // 获取 crowdloan 列表
  async getCrowdloanList() {
    let [currentPeriod] = await this.getCurrentPeriod();
    const campaigns = await this.useFunds()
    let funds = campaigns.funds.filter( v => {
      v.cap = util.fixedByDecimal( v.info.cap, this.decimals );
      v.raised = util.fixedByDecimal( v.info.raised, this.decimals );
      v.humanData = v.info.toHuman();
      return !( v.isCapped || v.isEnded || v.isWinner ) && currentPeriod <= v.info.firstPeriod
    })
    return funds;
  }

  // 获取 单个 crowdloan 详情
  async getCrowdloanInfo( paraId ) {
    const api = await this.api;
    let bestNumber = await this.getCurrentBlock();
    let info = await api.query.crowdloan.funds( paraId );
    let JSONInfo = info?.toJSON();
    if ( JSONInfo ) {
      JSONInfo.cap = util.formatNum( JSONInfo?.cap, this.decimals );
      JSONInfo.raised = util.formatNum( JSONInfo?.raised, this.decimals );
      JSONInfo.endTime =  util.timestampToDate( util.BigNumber( JSONInfo.end ).minus( bestNumber ).times( 6000 ).plus( Date.now() ).toNumber() )
    }
  return JSONInfo;
  }

  async endTimeToUTC( endBlock ) {
    if ( !endBlock ) {
      return null;
    }
    let api = await this.api;
    let bestNumber = await this.getCurrentBlock();
    let total = api.consts.auctions?.endingPeriod;
    let remain = bestNumber.lt(endBlock) ?
      endBlock.sub(bestNumber)
      : total.sub( bestNumber ).add( endBlock );

    // let remain = total.sub(bestNumber).add(endBlock)
    const blockTime = (
      api.consts.babe?.expectedBlockTime ||
      api.consts.difficulty?.targetBlockTime ||
      api.consts.timestamp?.minimumPeriod.muln(2) ||
      new BN(6000)
    );
    const time = blockTime.mul(remain).add(new BN(Date.now())).toNumber();

    return remain.toNumber() < 0 ? '':util.timestampToDate( time );
  }

  // 操作方法
  async setSigner( account ) {
    const api = await this.api;
    const injector = await web3FromSource( account.meta.source ).catch( () => { alert( "Please refresh the page" ) } );
    api.setSigner( injector.signer );
    return api;
  }
}

export default User;
