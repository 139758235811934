export default {
  kusama: {
    defaultAmount: 0.1,
    symbol: 'KSM',
    decimals: 12,
    chain_id: 32,
    ws: null,
    ss58Formats: 2,
    chain: 'kusama',
    defaultIcon: require('@/assets/img/icon/default.png'),
    navLogo: require( '@/assets/img/icon/logo.png' ),
    loginLogo: require( '@/assets/img/banner/1.jpg' ),
    twitter:'https://twitter.com/KusamaProject',
    withdraw: 'https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Fkusama-rpc.polkadot.io#/parachains/crowdloan',
    dataLink: 'https://kusama.subscan.io/auction_board',
    websockets: [
      "wss://kusama.maiziqianbao.net/ws",
      "wss://kusama.api.onfinality.io/public-ws",
      "ws://47.97.254.221:1322",
      "wss://kusama-rpc.polkadot.io",
      "wss://cc3-5.kusama.network/",
    ],
  },
  polkadot: {
    defaultAmount:5,
    symbol: 'DOT',
    decimals: 10,
    chain_id: 24,
    ws: null,
    ss58Formats: 0,
    chain: 'polkadot',
    defaultIcon: require('@/assets/img/icon/polka-default.png'),
    navLogo: require( '@/assets/img/icon/polka.png' ),
    loginLogo: require( '@/assets/img/banner/2.jpg' ),
    twitter: 'https://twitter.com/polkaprojectcom',
    withdraw: 'https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Frpc.polkadot.io#/parachains/crowdloan',
    dataLink: 'https://polkadot.subscan.io/auction_board',
    websockets: [
      "wss://polkadot.maiziqianbao.net/ws",
      "wss://rpc.polkadot.io"
    ],
  },
  // websockets: {
  //   kusama: [
  //     "wss://kusama.maiziqianbao.net/ws",
  //     "wss://kusama.api.onfinality.io/public-ws",
  //     "ws://47.97.254.221:1322",
  //     "wss://kusama-rpc.polkadot.io",
  //     "wss://cc3-5.kusama.network/",
  //   ],
  //   polkadot: ["wss://polkadot.maiziqianbao.net/ws"],
  //   rococo: [
  //     "wss://rococo-rpc.polkadot.io/",
  //     "wss://rpc.rococo.plasmnet.io/",
  //     "wss://rococo.polkabtc.io/api/parachain",
  //     "wss://pc2-rpc.darwinia.network/",
  //     "wss://para.rococo-v1.kilt.io/",
  //   ],
  // },
  // ss58Formats = {
  //   rococo: 42,
  //   polkadot: 0,
  //   edgeware: 7,
  //   kusama: 2,
  //   kulupu: 16,
  // },
  // ksm: {
  //   chain: 'kusama',
  //   navLogo: require( '@/assets/img/icon/logo.png' ),
  //   loginLogo: require( '@/assets/img/banner/1.jpg' ),
  //   twitter:'https://twitter.com/KusamaProject',
  //   withdraw: 'https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Fkusama-rpc.polkadot.io#/parachains/crowdloan',
  //   dataLink: 'https://kusama.subscan.io/auction_board',
  // },
  // dot: {
  //   chain: 'polkadot',
  //   navLogo: require( '@/assets/img/icon/polka.png' ),
  //   loginLogo: require( '@/assets/img/banner/2.jpg' ),
  //   twitter: 'https://twitter.com/polkaprojectcom',
  //   withdraw: 'https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Frpc.polkadot.io#/parachains/crowdloan',
  //   dataLink: 'https://polkadot.subscan.io/auction_board',
  // },
}